body {
  background: #f6f6f6;
}
.logo {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.logo-img {
  width: 25rem;
}
.santa-img {
  top: 56rem;
  width: 35%;
  left: 2%;
  rotate: -11deg;
  animation: motion 1s linear 0s infinite alternate;
}
@keyframes motion {
  0% {
    margin-top: 0px;
  }
  100% {
    margin-top: 10px;
  }
}
.snowman-img,
.ginger-bread-img {
  width: 6rem;
}
.snowflake-img {
  width: 2.7rem;
  position: absolute;
  top: 86%;
  right: 10%;
  animation: rotate_image 6s linear infinite;
  transform-origin: 50% 50%;
}
.snow-img {
  width: 2.5rem;
  position: absolute;
  left: 10%;
  top: 150%;
  animation: rotate_image 6s linear infinite;
  transform-origin: 50% 50%;
}
@keyframes rotate_image {
  100% {
    transform: rotate(360deg);
  }
}
.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.editor-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  padding-bottom: 7rem;
}
.editor-input-container {
  width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.editor-input-container > div {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}
.equation-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.add-input-button {
  width: 5rem;
  height: 5rem;
  border: none;
  background: #ffffff;
  border-radius: 50px;
  color: #a3a3a3;
  font-size: 3rem;
  border: 1px solid gainsboro;
  align-self: center;
  cursor: pointer;
}
.add-input-button:hover {
  box-shadow: 0px 0px 10px #cfcfcf;
}
.editor-btn-box {
  display: flex;
  gap: 1rem;
}
.editor-btn-box > button:hover {
  border: none;
  background: rgb(0, 91, 0);
  width: 8rem;
  height: 3rem;
  font-weight: 700;
  color: white;
  border-radius: 30px;
  cursor: pointer;
}
.editor-btn-box > button {
  border: none;
  background: green;
  width: 8rem;
  height: 3rem;
  font-weight: 700;
  color: white;
  border-radius: 30px;
  cursor: pointer;
}
math-field {
  height: 3rem;
  border-bottom: 2px solid #c5c5c5;
  padding: 0.3rem 1rem;
  font-weight: 300;
  color: #151515;
  display: flex;
  align-items: center;
}
.result-box {
  border: 1px solid #d9d9d9;
  box-shadow: 1px 1px 7px #e9e9e9;
  border-radius: 20px;
  padding: 1rem;
  background: #f8f8f8;
  font-family: -webkit-body;
  display: flex;
  flex-direction: column;
}
.result-box > button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.2rem;
  text-align: start;
}
.result-box > button:hover {
  background-color: #eeeeee;
}
.delete-btn {
  border: none;
  background: none;
  cursor: pointer;
}
.delete-btn > img {
  width: 2rem;
}
